<template>
  <div class="fluid" id="CustomerList">
    <div>
      <div class="customer-list-div">
        <v-card class="px-3 py-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
                CUSTOMER LIST
              </h6>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="customer-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <div>
                    <div style="width: 120px">
                      <v-select
                        :disabled="selectedData.length === 0"
                        label="Action"
                        style="
                      position: relative;
                      top: 15px;
                      margin-left: 5px;
                      font-size: 12px;
                    "
                        v-model="actionValue"
                        :items="[
                          { id: 0, name: '' },
                          { id: 1, name: 'Delete' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                        @change="action"
                      ></v-select>
                    </div>
                  </div>
                  <div style="width:160px;margin-left:10px;">
                    <v-select
                      @change="companyWatcher"
                      v-model="paramAPI.company_id"
                      :items="dropdown.company"
                      style="
                      position: relative;
                      top: 15px;
                      margin-left: 5px;
                      font-size: 12px;
                    "
                      label="Company"
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      dense
                      :disabled="getUserProfile.employee.company.plant_id != 6"
                    ></v-select>
                  </div>

                  <div style="width: 160px;margin-left:10px;">
                    <v-select
                      label="Type"
                      style="
                      position: relative;
                      top: 15px;
                      margin-left: 5px;
                      font-size: 12px;
                    "
                      v-model="paramAPI.type"
                      :items="[
                        { id: 1, name: 'SanQua Group Customer' },
                        { id: 2, name: 'Potential Customer' },
                        { id: 3, name: 'Other Customer' }
                      ]"
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      dense
                      clearable
                      @change="typeWatcher"
                    ></v-select>
                  </div>
                  <div class="customer-list-toolbar-1">
                    <div style="width: 220px;margin-left:auto;">
                      <v-text-field
                        v-model="paramAPI.keyword"
                        label="cari customer"
                        type="search"
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        style="position: relative; top: 15px; margin-left: 10px;"
                        @keyup.enter="searchEnter"
                        :disabled="loading"
                      >
                      </v-text-field>
                    </div>
                    <!-- <div
                      style="margin-left:20px;display:flex;justify-content:center;align-items:center;"
                    >
                      <v-btn
                        rounded
                        outlined
                        small
                        elevation="1"
                        color="indigo"
                        class="indigo--text"
                        style="font-size:12px;"
                        @click="searchEnter"
                        :disabled="loading"
                      >
                        search
                      </v-btn>
                    </div> -->
                  </div>
                </v-toolbar>

                <v-toolbar
                  class="customer-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        elevation="1"
                        color="indigo"
                        class="indigo--text font-weight-bold"
                        style="font-size:12px;margin-left:5px;"
                        @click="addCategory"
                        :disabled="loading"
                      >
                        Tambah customer
                      </v-btn>
                    </template>

                    <v-form
                      :disabled="loading"
                      ref="entryForm"
                      @submit.prevent="submit"
                      style="position: relative;"
                    >
                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Tambah Customer
                        </v-card-title>

                        <v-card-text class="d-flex justify-center align-center">
                          <v-col
                            md="12"
                            style="padding: 0; position:relative; top:20px;"
                          >
                            <div style="padding: 0 10px">
                              <div style="margin-bottom:10px;">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                  >
                                    Company
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0;">
                                  <v-autocomplete
                                    dense
                                    v-model="form.company"
                                    :items="dropdown.company"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    outlined
                                    clearable
                                    style="margin: 0;height: 40px;"
                                    :disabled="
                                      getUserProfile.employee.company
                                        .plant_id != 6
                                    "
                                  ></v-autocomplete>
                                </v-col>
                              </div>
                              <div style="margin-bottom:10px;">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                  >
                                    Kategori
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0;">
                                  <v-autocomplete
                                    dense
                                    :items="[
                                      { id: 1, name: 'SanQua Group Customer' },
                                      { id: 2, name: 'Potential Customer' },
                                      { id: 3, name: 'Other' }
                                    ]"
                                    item-text="name"
                                    item-value="id"
                                    return-id
                                    outlined
                                    clearable
                                    style="margin: 0;height: 40px;"
                                    v-model="form.type"
                                  ></v-autocomplete>
                                </v-col>
                              </div>
                              <div>
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                  >
                                    Nama Customer
                                  </p>
                                </v-col>
                                <v-col
                                  cols="12"
                                  style="padding:0; margin-bottom:10px;"
                                >
                                  <v-text-field
                                    dense
                                    v-model="form.name"
                                    outlined
                                    :rules="nameRules"
                                    style="height:40px;"
                                  />
                                </v-col>
                              </div>
                              <div>
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                  >
                                    Code
                                  </p>
                                </v-col>
                                <v-col
                                  cols="12"
                                  style="padding:0; margin-bottom:10px;"
                                >
                                  <v-text-field
                                    dense
                                    v-model="form.code"
                                    outlined
                                    style="height:40px;"
                                  />
                                </v-col>
                              </div>
                              <div>
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                  >
                                    Email
                                  </p>
                                </v-col>
                                <v-col
                                  cols="12"
                                  style="padding:0; margin-bottom:10px;"
                                >
                                  <v-text-field
                                    dense
                                    v-model="form.email"
                                    outlined
                                    style="height:40px;"
                                  />
                                </v-col>
                              </div>
                              <div>
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                  >
                                    Handphone
                                  </p>
                                </v-col>
                                <v-col
                                  cols="12"
                                  style="padding:0; margin-bottom:10px;"
                                >
                                  <v-text-field
                                    dense
                                    v-model="form.mobile"
                                    outlined
                                    style="height:40px;"
                                  />
                                </v-col>
                              </div>

                              <div>
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                  >
                                    Telephone
                                  </p>
                                </v-col>
                                <v-col
                                  cols="12"
                                  style="padding:0; margin-bottom:10px;"
                                >
                                  <v-text-field
                                    dense
                                    v-model="form.phone"
                                    outlined
                                    style="height:40px;"
                                  />
                                </v-col>
                              </div>
                              <div>
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                  >
                                    Alamat
                                  </p>
                                </v-col>
                                <v-col
                                  cols="12"
                                  style="padding:0; margin-bottom:10px;"
                                >
                                  <v-text-field
                                    dense
                                    v-model="form.street"
                                    outlined
                                    style="height:40px;"
                                  />
                                </v-col>
                              </div>

                              <div style="margin-bottom:10px;">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                  >
                                    Region
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0;">
                                  <v-autocomplete
                                    dense
                                    v-model="form.region"
                                    :items="dropdown.region"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    outlined
                                    clearable
                                    style="margin: 0;height: 40px;"
                                  ></v-autocomplete>
                                </v-col>
                              </div>
                              <div style="margin-bottom:10px;">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                  >
                                    Region Group
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0;">
                                  <v-autocomplete
                                    dense
                                    v-model="form.region_group"
                                    :items="dropdown.regionGroup"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    outlined
                                    clearable
                                    style="margin: 0;height: 40px;"
                                  ></v-autocomplete>
                                </v-col>
                              </div>

                              <div style="margin-bottom:10px;">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                  >
                                    Region Master
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0;">
                                  <v-autocomplete
                                    dense
                                    v-model="form.region_master"
                                    :items="dropdown.regionMaster"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    outlined
                                    clearable
                                    style="margin: 0;height: 40px;"
                                  ></v-autocomplete>
                                </v-col>
                              </div>
                            </div>
                          </v-col>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            color="error"
                            text
                            outlined
                            @click="close"
                            :loading="loading"
                          >
                            Batal
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            type="submit"
                            text
                            outlined
                            :loading="loading"
                          >
                            Simpan
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                </v-toolbar>
              </div>
              <v-toolbar
                class="customer-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  @keyup.enter="searchEnter"
                  :disabled="loading"
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="60vh"
                v-model="selectedData"
                :headers="headers"
                style="cursor: pointer;"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                show-select
                :single-select="true"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.type`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ findType(item.type) }}
                  </div>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ convertDate(item.createdAt) }}
                  </div>
                </template>
                <template v-slot:[`item.updatedAt`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ convertDate(item.updatedAt) }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'leavecategory',
  data: () => ({
    dialog: false,
    hrsApi: buildType.apiURL('hrs'),
    odoo: buildType.apiURL('odoo'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      sortBy: 'name',
      sortType: 'asc',
      itemsPerPage: 10,
      company_id: null,
      type: null
    },
    totalData: 0,

    options: {
      page: 1,
      itemsPerPage: 10,
      sortDesc: []
    },
    actionValue: 0,
    headers: [
      {
        text: 'Code',
        value: 'code',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Company',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Email',
        value: 'email',
        align: 'left',
        sortable: false
      },
      {
        text: 'Phone',
        value: 'phone',
        align: 'left',
        sortable: false
      },
      {
        text: 'Mobile',
        value: 'mobile',
        align: 'left',
        sortable: false
      },
      {
        text: 'Type',
        value: 'type',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Buat',
        value: 'createdAt',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Update',
        value: 'updatedAt',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    selectedData: [],
    loading: false,
    form: {
      act: 'add',
      name: '',
      code: '',
      street: '',
      mobile: '',
      phone: '',
      email: '',
      region_group: null,
      region_master: null,
      region: null,
      company: null,
      type: null
    },
    nameRules: [],
    dropdown: {
      company: [],
      region: [],
      regionGroup: [],
      regionMaster: []
    }
  }),

  async mounted() {
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    this.dropdownPlant()
    await this.getDataFromApi()
    this.setAction()
    this.dropdownRegion()
    this.dropdownRegionGroup()
    this.dropdownRegionMaster()
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    async companyWatcher(p) {
      this.paramAPI.company_id = p

      await this.getDataFromApi()
    },
    async typeWatcher(p) {
      await this.getDataFromApi()
    },
    close() {
      this.nameRules = []
      this.form = {
        act: 'add',
        name: '',
        code: '',
        street: '',
        mobile: '',
        phone: '',
        email: '',
        region_group: null,
        region_master: null,
        region: null,
        company: null,
        type: null
      }
      this.dialog = false
    },
    action(event) {
      switch (event) {
        case 1:
          this.delete()
          break
      }
    },
    searchEnter() {
      this.getDataFromApi()
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then(data => {
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      let url = `${
        this.hrsApi
      }master/universal/salesmanothercustomer/list?keyword=${
        this.paramAPI.keyword
      }&order_by=${this.paramAPI.sortBy}&order_type=${
        this.paramAPI.sortType
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }`
      if (this.paramAPI.company_id !== null && this.paramAPI.type !== null) {
        url =
          url +
          `&filter=[{"company_id":${this.paramAPI.company_id},"type":${this.paramAPI.type}}]`
      }
      return await new Promise(resolve => {
        axios
          .get(url)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    addCategory() {
      this.form.act = 'add'
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    rowClick(pItem) {
      console.log(pItem)
      this.form.act = 'update'
      this.form.id = pItem.id
      this.form.name = pItem.name
      this.form.code = pItem.code
      this.form.street = pItem.street
      this.form.mobile = pItem.mobile
      this.form.phone = pItem.phone
      this.form.email = pItem.email
      this.form.region_group = {
        id: pItem.region_group_id,
        name: pItem.region_group_name
      }
      this.form.region_master = {
        id: pItem.region_master_id,
        name: pItem.region_master_name
      }
      this.form.region = {
        id: pItem.region_id,
        name: pItem.region_name
      }
      this.form.company = {
        id: pItem.company_id,
        name: pItem.company_name
      }
      this.form.type = pItem.type
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    dropdownPlant() {
      const app = this.getUserProfile.level.find(
        ({ application }) => application.id === 21
      )
      try {
        if (app.user_user_level.allowed_company !== null) {
          this.dropdown.company = app.user_user_level.allowed_company
        } else {
          this.dropdown.company = []
        }
      } catch (error) {
        console.log(error)
      }
    },
    async delete() {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async res => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .delete(
              `${this.hrsApi}master/universal/salesmanothercustomer/delete/${this.selectedData[0].id}`
            )
            .then(async res => {
              this.setAction()
              this.showMsgDialog('success', res.data.status_msg, 'false')
              await this.getDataFromApi()
            })
            .catch(err => {
              console.log(err)
            })
          this.loading = false
        } else {
          this.actionValue = 0
        }
      })
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },

    submit() {
      this.nameRules = [v => !!v || 'Name is required']
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        name: this.form.name,
        code: this.form.code,
        street: this.form.street,
        mobile: this.form.mobile,
        phone: this.form.phone,
        email: this.form.email,
        region_group_id:
          this.form.region_group !== null ? this.form.region_group.id : null,
        region_group_name:
          this.form.region_group !== null ? this.form.region_group.name : null,
        region_master_id:
          this.form.region_master !== null ? this.form.region_master.id : null,
        region_master_name:
          this.form.region_master !== null
            ? this.form.region_master.name
            : null,
        region_id: this.form.region !== null ? this.form.region.id : null,
        region_name: this.form.region !== null ? this.form.region.name : null,
        company_id: this.form.company.id,
        company_name: this.form.company.name,
        type: this.form.type
      }
      if (this.form.act === 'update') {
        Object.assign(form, { id: this.form.id })
      }
      console.log(form)
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}master/universal/salesmanothercustomer/save`, form)
        .then(async res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            await this.getDataFromApi()
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    dropdownRegion() {
      axios
        .get(
          `${this.odoo}master/universal/region.region/dropdown?keyword=&offset=0&limit=all`
        )
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.region = res.data.data)
          }
          return (this.dropdown.region = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.region = [])
        })
    },
    dropdownRegionGroup() {
      axios
        .get(
          `${this.odoo}master/universal/region.group/dropdown?keyword=&offset=0&limit=all`
        )
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.regionGroup = res.data.data)
          }
          return (this.dropdown.regionGroup = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.regionGroup = [])
        })
    },
    dropdownRegionMaster() {
      axios
        .get(
          `${this.odoo}master/universal/region.master/dropdown?keyword=&offset=0&limit=all`
        )
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.regionMaster = res.data.data)
          }
          return (this.dropdown.regionMaster = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.regionMaster = [])
        })
    },
    findType(val) {
      if (val !== null) {
        switch (val) {
          case 1:
            return 'SQA Customer'

          case 2:
            return 'Potential Customer'

          case 3:
            return 'Other'

          default:
            return ''
        }
      }
      return ''
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString('id')
        return local
      }
    }
  }
}
</script>
<style lang="scss">
#CustomerList {
  position: relative;
  .customer-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .customer-list-toolbar-1 {
      .customer-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .customer-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #CustomerList {
    .customer-list-div {
      .customer-list-toolbar-1 {
        .customer-list-toolbar-1 {
          display: none;
        }
      }
      .customer-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
